import { Link } from 'gatsby'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SnackbarProvider from 'react-simple-snackbar'
const $ = require("jquery");

const Header = () => {
    return (
        <SnackbarProvider>
            <HeaderHandler />
        </SnackbarProvider>
    )
}
function findIndexfromOptionName( select, optionValue ) {
	let options = Array.from( select.options );
	return options.findIndex( (opt) => opt.value == optionValue );
}
// function GetQueryString(key) {
//     var queryStringValue = "";
//     var vars = [], hash;
//     var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
//     for(var i = 0; i < hashes.length; i++)
//     {
//         hash = hashes[i].split('=');
//         vars.push(hash[0]);
//         vars[hash[0]] = hash[1];
//     }
//     if(vars[key] != undefined){
//         queryStringValue = decodeURIComponent(vars[key]);
//     }
//     return queryStringValue;
// }
class CustomDropdown extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var x, i, j, l, ll, selElmnt, a, b, c

        /* look for any elements with the class "lang-custom-select" */
        x = document.getElementsByClassName("lang-custom-select")
        l = x.length
		var lang = window.sessionStorage.getItem("lang");

        for (i = 0; i < l; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0]
            ll = selElmnt.length

            /* for each element, create a new div that will act as the selected item */
            a = document.createElement("div")
            a.setAttribute("class", "lang-select-selected app-selected-default-lang")
			var selElmnt_OptionIndex
			if(lang == null || lang == '' || lang == 'en'){
				selElmnt_OptionIndex = findIndexfromOptionName(selElmnt, "en")
			}
			else if (lang == 'vi'){
				selElmnt_OptionIndex = findIndexfromOptionName(selElmnt, "vi")
			}
			// var selOpt_innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML
			// var selOpt_value = selElmnt.options[selElmnt.selectedIndex].value
			var selOpt_innerHTML = selElmnt.options[selElmnt_OptionIndex].innerHTML
			var selOpt_value = selElmnt.options[selElmnt_OptionIndex].value
            a.innerHTML = "<span>" + selOpt_innerHTML + "</span>"// selElmnt.options[selElmnt.selectedIndex].innerHTML
			a.setAttribute("title", selOpt_value)
            x[i].appendChild(a)

            /* for each element, create a new div that will contain the option list */
            b = document.createElement("div")
            b.setAttribute("class", "lang-select-items lang-select-hide")

            for (j = 0; j < ll; j++) {
                /* for each option in the original select element, create a new div that will act as an option item */
                c = document.createElement("div")				
				var selOpt1_innerHTML = selElmnt.options[j].innerHTML
				var selOpt1_value = selElmnt.options[j].value
                c.innerHTML = "<span>" + selElmnt.options[j].innerHTML + "</span>"
				c.setAttribute("title", selOpt1_value)
				if(lang == null || lang == ''){
					if(selOpt1_value == 'en'){
						c.setAttribute("class", "lang-same-as-selected")
					}
				}
				else {
					if(selOpt1_value == lang) {
						c.setAttribute("class", "lang-same-as-selected")
					}
				}

                c.addEventListener("click", function (e) {
                    /* when an item is clicked, update the original select box, and the selected item */
                    var y, i, k, s, h, sl, yl
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0]
                    sl = s.length
                    h = this.parentNode.previousSibling

                    for (i = 0; i < sl; i++) {
						var selectedTitle = this.getAttribute("title")
						var slopt_innerHTML = s.options[i].innerHTML
						var slopt_value = s.options[i].value
                        // if (s.options[i].innerHTML === this.innerHTML) {
						if (slopt_value === selectedTitle) {
                            s.selectedIndex = i
                            h.innerHTML = "<span>" + this.innerHTML + "</span>"
							h.setAttribute("title", selectedTitle)
                            y = this.parentNode.getElementsByClassName("lang-same-as-selected")
                            yl = y.length

                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class")
                            }

                            this.setAttribute("class", "lang-same-as-selected")
							// set sessionStorage
							sessionStorage.setItem("lang", selectedTitle)
							if(selectedTitle === 'en'){
								$(".app-data-vi").each (function (){
									$(this).hide();
								});
								$(".app-data-vi-info").each (function (){
									$(this).attr("style", "display: none !important");;
								});
								$(".app-data-en").each (function (){
									$(this).show();
								});
								$(".app-data-en-info").each (function (){
									$(this).show();
								});
								$("body").each (function (){
									$(this).removeClass("app-en-lang");
								});
								$("body").each (function (){
									$(this).removeClass("app-vi-lang");
								});
								$("body").each (function (){
									$(this).addClass("app-en-lang");
								});
							}
							else if(selectedTitle === 'vi'){
								$(".app-data-en").each (function (){
									$(this).hide();
								});
								$(".app-data-en-info").each (function (){
									$(this).attr("style", "display: none !important");;
								});
								$(".app-data-vi").each (function (){
									$(this).show();
								});
								$(".app-data-vi-info").each (function (){
									$(this).show();
								});
								$("body").each (function (){
									$(this).removeClass("app-en-lang");
								});
								$("body").each (function (){
									$(this).removeClass("app-vi-lang");
								});
								$("body").each (function (){
									$(this).addClass("app-vi-lang");
								});
							}
							// Start set value on contact-us page
							var lang = window.sessionStorage.getItem("lang");
							// var vname = GetQueryString("name")
							// if(vname != ""){
							// 	if(lang == "vi") {
							// 		$("#app-contactus-name").each ( function (){
							// 			$(this).val("Yêu Cầu Bản Thử Nghiệm");
							// 		});
							// 	}
							// 	else {
							// 		$("#app-contactus-name").each ( function (){
							// 			$(this).val(vname);
							// 		});
							// 	}
							// }
							// var vsubject = GetQueryString("subject")
							// if(vsubject != ""){
							// 	if(lang == "vi") {
							// 		$("#app-contactus-subject").each ( function (){
							// 			$(this).val("Yêu Cầu Bản Thử Nghiệm");
							// 		});
							// 	}
							// 	else {
							// 		$("#app-contactus-subject").each ( function (){
							// 			$(this).val(vsubject);
							// 		});
							// 	}
							// }
							if(lang == "vi"){
								$("#app-contactus-name").each ( function (){
									$(this).attr('placeholder','Tên');
								});
								$("#app-contactus-subject").each ( function (){
									$(this).attr('placeholder','Tiêu Đề');
								});
								$(".app-section-contactus .app-contactus-detail .app-contactus-left .custom-select .select-selected").each ( function (){
									$(this).html("Chủ Đề");
								});
								$("#app-contactus-message").each ( function (){
									$(this).attr('placeholder','Nội Dung');
								});
								$("#app-contact-us-label-validation").each ( function (){
									$(this).html('Để xác thực, xin vui lòng nhập ngày của hôm qua theo định dạng (Tháng/Ngày/Năm). Ví dụ: Hôm qua là ngày 1 tháng 9 năm 2024, bạn sẽ nhập là "09/01/2024".');
								});
								// $("#app-contactus-btn").each ( function (){
								// 	$(this).val("Gửi");
								// });
							}
							else {
								$("#app-contactus-name").each ( function (){
									$(this).attr('placeholder','Name');
								});
								$("#app-contactus-subject").each ( function (){
									$(this).attr('placeholder','Subject');
								});
								$(".app-section-contactus .app-contactus-detail .app-contactus-left .custom-select .select-selected").each ( function (){
									$(this).html("I'm interested in…");
								});
								$("#app-contactus-message").each ( function (){
									$(this).attr('placeholder','Message');
								});
								$("#app-contact-us-label-validation").each ( function (){
									$(this).html("Please enter yesterday's date for validation (MM/DD/YYYY)");
								});
								// $("#app-contactus-btn").each ( function (){
								// 	$(this).val("Send Message");
								// });
							}
							// End Start set value on contact-us page
                            break
                        }
                    }
                    h.click()
                })
                b.appendChild(c)
            }
            x[i].appendChild(b)

            a.addEventListener("click", function (e) {
                /* when the select box is clicked, close any other select boxes, and open/close the current select box:*/
                e.stopPropagation()
                closeAllSelect(this)
                this.nextSibling.classList.toggle("lang-select-hide")
                this.classList.toggle("lang-select-arrow-active")
				// var this_title = this.getAttribute("title")
				// var a_title = a.getAttribute("title")
                // // if (a.innerHTML === "English (United States)") {
				// if(this_title === a_title){
                //     this.classList.remove("app-selected-default");
                //     this.classList.add("app-selected-default");
                // } else {
                //     this.classList.remove("app-selected-default");
                // }
            })
        }

        function closeAllSelect(elmnt) {
            /* a function that will close all select boxes in the document, except the current select box */
            var x, y, i, xl, yl, arrNo = []
            x = document.getElementsByClassName("lang-select-items")
            y = document.getElementsByClassName("lang-select-selected")
            xl = x.length
            yl = y.length

            for (i = 0; i < yl; i++) {
                if (elmnt === y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("lang-select-arrow-active")
                }
            }

            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("lang-select-hide")
                }
            }
        }

        /* if the user clicks anywhere outside the select box, then close all select boxes */
        document.addEventListener("click", closeAllSelect)
    }

    render() {
        return(
            <div className="lang-custom-select">
                <select name="choice">
                    <option value="en">English (United States)</option>
                    <option value="vi">Tiếng Việt (Vietnam)</option>
                </select>
            </div>
        )
    }
}

const HeaderHandler = () => {
	
	function handleToggleMenu() {
		document.querySelector('.menu').classList.toggle('open');
		document.querySelector('.btnClose').style.display = 'block';
		// document.querySelector('.overlay').style.display = 'block';
		document.querySelector('body').style.overflow = 'hidden';
		document.querySelector('.btn-showmenu').classList.toggle('open');
	}

	function handleClosebtn() {
		document.querySelector('.menu').classList.remove('open');
		document.querySelector('.btnClose').style.display = 'none';
		// document.querySelector('.overlay').style.display = 'none';
		document.querySelector('body').style.overflow = 'visible';
		document.querySelector('.btn-showmenu').classList.toggle('open');
	}

	function handleScrollDisable() {
		document.querySelector('body').style.overflow = 'visible';
	}

	return (
		<div className="header">
			<Container>
				<div className="header-wrap">
					<button className="btn-showmenu" onClick={handleToggleMenu} onKeyDown={handleToggleMenu}>
						<img src="/app-icon-menu.svg" alt="app icon menu" />
					</button>
					<button className="btnClose" onClick={handleClosebtn} onKeyDown={handleClosebtn}>
						<img src="/app-icon-close.svg" alt="app icon down" />
					</button>
					{/* <div className="overlay" onClick={handleClosebtn} onKeyDown={handleClosebtn}></div> */}
					<div className="logo">
						<Link to="/" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
							<img src="/logo.svg" alt="Logo" />
						</Link>
					</div>
					<div className="menu">
						<ul className="menu-link">
							{/* <li className="has-sub">
								<h6>
									Our Products
									<img className="app-icon-dot" src="/app-icon-dot.svg" alt="app icon dot" />
								</h6>
								<div className="sub">
									<Row className="app-multiple-row-custom-1">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													Appvity eProduct Portfolio
													<h6>Build collaboration apps to connect everyone for your enterprise productivity.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={8}>
											<div className="app-sub-child">
												<Link to="/products/etask" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-etask.svg" alt="app icon etask" />
														<img className="app-img-mobile-tablet" src="/app-icon-etask-mobile-tablet.svg" alt="app icon etask mobile tablet" />
														eTask
													</div>
													<h6>Manage your tasks from different data sources across organizations. </h6>
												</Link>
											</div>
											<div className="app-sub-child">
												<Link to="/products/ehelpdesk" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-ehelpdesk.svg" alt="app icon ehelpdesk" />
														<img className="app-img-mobile-tablet" src="/app-icon-ehelpdesk-mobile-tablet.svg" alt="app icon ehelpdesk mobile tablet" />
														eHelpdesk
													</div>
													<h6>Easy to track every issue from your customers and boost your business’ credibility.</h6>
												</Link>
											</div>
										</Col>
									</Row>
									<Row className="app-multiple-row-custom-2">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/add-ins" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													Add-Ins
													<h6>Better working experience between eProducts and Microsoft apps.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={8}>
											<div className="app-sub-child">
												<Link to="/products/excel-sync" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-excel-sync-header.svg" alt="app icon excel sync" />
														<img className="app-img-mobile-tablet" src="/app-icon-excel-sync-header-mobile-tablet.svg" alt="app icon excel sync mobile tablet" />
														Excel Sync
													</div>
													<h6>Manage data sourced from SharePoint or Appvity eSource within a Microsoft Excel spreadsheet.</h6>
												</Link>
											</div>
											<div className="app-sub-child">
												<Link to="/products/outlook" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-outlook.svg" alt="app icon outlook" />
														<img className="app-img-mobile-tablet" src="/app-icon-outlook-mobile-tablet.svg" alt="app icon outlook mobile tablet" />
														Outlook
													</div>
													<h6>Manage data sourced from SharePoint or Appvity eSource within a Microsoft Excel spreadsheet.</h6>
												</Link>
											</div>
										</Col>
									</Row>
								</div>
							</li>
							<li className="has-sub">
								<h6>
									Our Services
									<img className="app-icon-dot" src="/app-icon-dot.svg" alt="app icon dot" />
								</h6>
								<div className="sub">
									<Row className="app-single-row-custom">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/services" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													Service Overview
													<h6>Focus on the simplest ways to solve existing business problems.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={8}>
											<div className="app-sub-child">
												<Link to="/services/software-development" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													Software Development Service
													<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
												</Link>
											</div>
											<div className="app-sub-child">
												<Link to="/services/solution" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													Solution Service
													<h6>Customize our defined solutions to fit your enterprise demand.</h6>
												</Link>
											</div>
										</Col>
									</Row>
								</div>
							</li>
							<li className="has-sub">
								<h6>
									Support
									<img className="app-icon-dot" src="/app-icon-dot.svg" alt="app icon dot" />
								</h6>
								<div className="sub">
									<Row className="app-single-row-custom">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/support" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													Support Center
													<h6>It's a place to assist you in time for any queries.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={8}>
											<div className="app-sub-child">
												<a href="/OnlineDoc">
													Online Docs
													<h6>Provide user guide, application notes, and technical related documents.</h6>
												</a>
											</div>
											<div className="app-sub-child">
												<Link to="/support/blogs" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													Blogs
													<h6>We share our experience related to applications and Microsoft technologies.</h6>
												</Link>
											</div>
										</Col>
									</Row>
								</div>
							</li> */}
							<li className="has-sub">
								<h6>
									<span className="app-data-en app-display-none">Products<img className="app-icon-menudown" src="/app-icon-menudown.svg" /></span>
									<span className="app-data-vi app-display-none">Sản Phẩm<img className="app-icon-menudown" src="/app-icon-menudown.svg" /></span>
									<img className="app-icon-dot" src="/app-icon-dot.svg" alt="app icon dot" />
								</h6>
								<div className="sub">
									<Row id="app-subnav-row-header-productportfolio" className="app-subnav-row-header">
										<Col xs={12} md={12} lg={12}>
											<h4 id="app-sub-nav-headertext-productportfolio" className="app-data-en app-sub-nav-headertext">Product Portfolio</h4>
											<h4 id="app-sub-nav-headertext-productportfolio" className="app-data-vi app-sub-nav-headertext">Danh Mục Sản Phẩm</h4>
										</Col>
									</Row>
									<Row className="app-subnav-row-content-top">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/efiles" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-efiles.svg" alt="app icon eFiles" />
														<img className="app-img-mobile-tablet" src="/app-icon-efiles-mobile-tablet.svg" alt="app icon eFiles mobile tablet" />
														eFiles
													</div>
													<h6 className="app-data-en-info">Advanced functionalities to enhance the management of files and folders in Teams and SharePoint.</h6>
													<h6 className="app-data-vi-info">Quản lý tài liệu trong Microsoft Teams và SharePoint với chức năng nâng cao.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/excel-sync" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-excel-sync-header.svg" alt="app icon excel sync" />
														<img className="app-img-mobile-tablet" src="/app-icon-excel-sync-header-mobile-tablet.svg" alt="app icon excel sync mobile tablet" />
														eXsync
													</div>
													<h6 className="app-data-en-info">Read and update data from SharePoint, or Appvity products within Microsoft Excel.</h6>
													<h6 className="app-data-vi-info">Đọc và quản lý dữ liệu từ SharePoint hoặc các ứng dụng của Appvity bằng Mircrosoft Excel.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/erequest" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-erequest.svg" alt="app icon eRequest" />
														<img className="app-img-mobile-tablet" src="/app-icon-erequest-mobile-tablet.svg" alt="app icon eRequest mobile tablet" />
														eRequest
													</div>
													<h6 className="app-data-en-info">Initiate approval requests effortlessly, regardless of location or context - whether it’s a document, a form, or any other business-related task.</h6>
													<h6 className="app-data-vi-info">Hỗ trợ nhiều loại yêu cầu tùy theo hoạt động của doanh nghiệp.</h6>
												</Link>
											</div>
										</Col>
									</Row>
									<Row className="app-subnav-row-content-bottom">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/etask" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-etask.svg" alt="app icon etask" />
														<img className="app-img-mobile-tablet" src="/app-icon-etask-mobile-tablet.svg" alt="app icon etask mobile tablet" />
														eTask
													</div>
													<h6 className="app-data-en-info">Manage your tasks from different data sources across organizations. </h6>
													<h6 className="app-data-vi-info">Quản lý công việc từ nhiều nguồn, và từ nhiều doanh nghiệp khác nhau.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/ehelpdesk" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-ehelpdesk.svg" alt="app icon eHelpdesk" />
														<img className="app-img-mobile-tablet" src="/app-icon-ehelpdesk-mobile-tablet.svg" alt="app icon eHelpdesk mobile tablet" />
														eHelpdesk
													</div>
													<h6 className="app-data-en-info">Effortlessly track every client issue and enhance your business’s credibility.</h6>
													<h6 className="app-data-vi-info">Theo dõi mọi vấn đề của khách hàng một cách dễ dàng và chính xác.</h6>
												</Link>
											</div>
										</Col>
									</Row>
									{/* <Row className="app-subnav-row-content-top">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/efiles" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-efiles.svg" alt="app icon eFiles" />
														<img className="app-img-mobile-tablet" src="/app-icon-efiles-mobile-tablet.svg" alt="app icon eFiles mobile tablet" />
														eFiles
													</div>
													<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/excel-sync" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-excel-sync-header.svg" alt="app icon excel sync" />
														<img className="app-img-mobile-tablet" src="/app-icon-excel-sync-header-mobile-tablet.svg" alt="app icon excel sync mobile tablet" />
														eXsync
													</div>
													<h6>Manage data sourced from SharePoint or Appvity eSource within a Microsoft Excel spreadsheet.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/eprofile" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-undefined.svg" alt="app icon eProfile" />
														<img className="app-img-mobile-tablet" src="/app-icon-undefined-mobile-tablet.svg" alt="app icon eProfile mobile tablet" />
														eProfile
													</div>
													<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
												</Link>
											</div>
										</Col>
									</Row>
									<Row className="app-subnav-row-content-middle">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/erequest" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-erequest.svg" alt="app icon eRequest" />
														<img className="app-img-mobile-tablet" src="/app-icon-erequest-mobile-tablet.svg" alt="app icon eRequest mobile tablet" />
														eRequest
													</div>
													<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/ehelpdesk" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-ehelpdesk.svg" alt="app icon eHelpdesk" />
														<img className="app-img-mobile-tablet" src="/app-icon-ehelpdesk-mobile-tablet.svg" alt="app icon eHelpdesk mobile tablet" />
														eHelpdesk
													</div>
													<h6>Easy to track every issue from your customers and boost your business’ credibility.</h6>
												</Link>
											</div>
										</Col>
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/etask" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-etask.svg" alt="app icon etask" />
														<img className="app-img-mobile-tablet" src="/app-icon-etask-mobile-tablet.svg" alt="app icon etask mobile tablet" />
														eTask
													</div>
													<h6>Manage your tasks from different data sources across organizations. </h6>
												</Link>
											</div>
										</Col>
									</Row>
									<Row className="app-subnav-row-content-bottom">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/products/elook" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<div className="app-sub-child-title">
														<img src="/app-icon-elook.svg" alt="app icon eLook" />
														<img className="app-img-mobile-tablet" src="/app-icon-elook-mobile-tablet.svg" alt="app icon eLook mobile tablet" />
														eLook
													</div>
													<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
												</Link>
											</div>
										</Col>
									</Row> */}
									<Row className="app-subnav-row-morelink">
										<Col xs={12} md={12} lg={12}>
											<div className="app-morelink-wrap">
												<Link to="/products" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<h6 className="app-data-en"><span>See All Products<img className="app-icon-menunext" src="/app-icon-menunext.svg" /><img className="app-icon-menunext-mobile-tablet" src="/app-icon-menunext-mobile-tablet.svg" /></span></h6>
													<h6 className="app-data-vi"><span>Tất Cả Sản Phẩm<img className="app-icon-menunext" src="/app-icon-menunext.svg" /><img className="app-icon-menunext-mobile-tablet" src="/app-icon-menunext-mobile-tablet.svg" /></span></h6>
												</Link>
											</div>
										</Col>
									</Row>
								</div>
							</li>
							<li className="has-sub">
								<h6>
									<span className="app-data-en app-display-none">Solutions<img className="app-icon-menudown" src="/app-icon-menudown.svg" /></span>
									<span className="app-data-vi app-display-none">Giải Pháp<img className="app-icon-menudown" src="/app-icon-menudown.svg" /></span>
									<img className="app-icon-dot" src="/app-icon-dot.svg" alt="app icon dot" />
								</h6>
								<div className="sub">
									<Row className="app-subnav-solutions">
										<Col xs={12} md={12} lg={8} className='app-subnav-solutions-colleft'>
											<Row className="app-subnav-row-header-solutions-left">
												<h4 className="app-data-en app-sub-nav-headertext-solutions-left">Small & Medium Business Solutions</h4>
												<h4 className="app-data-vi app-sub-nav-headertext-solutions-left">Giải Pháp Cho Doanh Nghiệp Vừa và Nhỏ</h4>
											</Row>
											<Row className="app-subnav-row-content-solutions-left-top">
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/products/erequest" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-erequest.svg" alt="app icon eRequest" />
																<img className="app-img-mobile-tablet" src="/app-icon-erequest-mobile-tablet.svg" alt="app icon eRequest mobile tablet" />
																eRequest
															</div>
															<h6 className="app-data-en-info">Initiate approval requests effortlessly, regardless of location or context - whether it’s a document, a form, or any other business-related task.</h6>
															<h6 className="app-data-vi-info">Hỗ trợ nhiều loại yêu cầu tùy theo hoạt động của doanh nghiệp.</h6>
														</Link>
													</div>
													
												</Col>
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/esign" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-esign.svg" alt="app icon eSign" />
																<img className="app-img-mobile-tablet" src="/app-icon-esign-mobile-tablet.svg" alt="app icon eSign mobile tablet" />
																eSign
															</div>
															<h6 className="app-data-en-info">Authorize document signatures within your team or with external clients using various types of digital certificates.</h6>
															<h6 className="app-data-vi-info">Ký kết tài liệu nội bộ hoặc tài liệu với khách hàng của bạn bằng nhiều loại chữ ký số khác nhau.</h6>
														</Link>
													</div>
												</Col>
											</Row>
											<Row className="app-subnav-row-content-solutions-left-bottom">
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/einvoice" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-einvoice.svg" alt="app icon eInvoice" />
																<img className="app-img-mobile-tablet" src="/app-icon-einvoice-mobile-tablet.svg" alt="app icon eInvoice mobile tablet" />
																eInvoice
															</div>
															<h6 className="app-data-en-info">Streamline invoice management while ensuring compliance with tax codes for seamless retrieval and efficient information handling.</h6>
															<h6 className="app-data-vi-info">Tối ưu hóa việc quản lý hóa đơn, đảm bảo tuân thủ luật và xử lý thông tin hiệu quả.</h6>
														</Link>
													</div>
													{/* <div className="app-sub-child">
														<Link to="/solutions/eorder" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-undefined.svg" alt="app icon eOrder" />
																<img className="app-img-mobile-tablet" src="/app-icon-undefined-mobile-tablet.svg" alt="app icon eOrder mobile tablet" />
																eOrder
															</div>
															<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
														</Link>
													</div> */}
												</Col>
												{/* <Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/econtract" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-undefined.svg" alt="app icon eContract" />
																<img className="app-img-mobile-tablet" src="/app-icon-undefined-mobile-tablet.svg" alt="app icon eContract mobile tablet" />
																eContract
															</div>
															<h6>Help manage contract creation, execution and amendments for the purpose of maximizing performance and minimizing risk.</h6>
														</Link>
													</div>
												</Col> */}
											</Row>
											{/* <Row className="app-subnav-row-content-solutions-left-top">
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/ebudget" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-ebudget.svg" alt="app icon eBudget" />
																<img className="app-img-mobile-tablet" src="/app-icon-ebudget-mobile-tablet.svg" alt="app icon eBudget mobile tablet" />
																eBudget
															</div>
															<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
														</Link>
													</div>
												</Col>
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/econtract" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-undefined.svg" alt="app icon eContract" />
																<img className="app-img-mobile-tablet" src="/app-icon-undefined-mobile-tablet.svg" alt="app icon eContract mobile tablet" />
																eContract
															</div>
															<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
														</Link>
													</div>
												</Col>
											</Row>
											<Row className="app-subnav-row-content-solutions-left-middle">
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/eorder" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-undefined.svg" alt="app icon eOrder" />
																<img className="app-img-mobile-tablet" src="/app-icon-undefined-mobile-tablet.svg" alt="app icon eOrder mobile tablet" />
																eOrder
															</div>
															<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
														</Link>
													</div>
												</Col>
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/esign" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-esign.svg" alt="app icon eSign" />
																<img className="app-img-mobile-tablet" src="/app-icon-esign-mobile-tablet.svg" alt="app icon eSign mobile tablet" />
																eSign
															</div>
															<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
														</Link>
													</div>
												</Col>
											</Row>
											<Row className="app-subnav-row-content-solutions-left-bottom">
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/einvoice" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-undefined.svg" alt="app icon eInvoice" />
																<img className="app-img-mobile-tablet" src="/app-icon-undefined-mobile-tablet.svg" alt="app icon eInvoice mobile tablet" />
																eInvoice
															</div>
															<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
														</Link>
													</div>
												</Col>
												<Col xs={12} md={12} lg={6}>
													<div className="app-sub-child">
														<Link to="/solutions/epayment" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
															<div className="app-sub-child-title">
																<img src="/app-icon-epayment.svg" alt="app icon ePayment" />
																<img className="app-img-mobile-tablet" src="/app-icon-epayment-mobile-tablet.svg" alt="app icon ePayment mobile tablet" />
																ePayment
															</div>
															<h6>Open a request for approval anytime, anywhere, whether it's a document, a project, or any other business-related task.</h6>
														</Link>
													</div>
												</Col>
											</Row> */}
										</Col>
										<Col xs={12} md={12} lg={4} className='app-subnav-solutions-colright'>
											<Row className="app-subnav-row-header-solutions-right">
												<h4 className="app-data-en app-sub-nav-headertext-solutions-right">Enterprise Solutions</h4>
												<h4 className="app-data-vi app-sub-nav-headertext-solutions-right">Giải Pháp Doanh Nghiệp</h4>
											</Row>	
											<Row className="app-subnav-row-content-solutions-right">
												<Col xs={12} md={12} lg={12} className='app-subnav-row-content-solutions-right-col'>
													<div className="app-sub-solutions-wrap-1">
														<div className="app-sub-solutions-left-wrap">
															<Link to="/solutions/procurementtopayment" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
																<img className="app-icon-desktop" src="/app-icon-procurementtopayment.png" alt="Procurement to Payment" />
																<img className="app-icon-mobile" src="/app-icon-procurementtopayment-mobile.png" alt="Procurement to Payment" />
																<div className="app-data-en app-sub-solutions-label"><h5>Procurement to Payment</h5></div>
																<div className="app-data-vi app-sub-solutions-label"><h5>Hệ Thống Quản Lý Thu Mua và Thanh Toán (P2P)</h5></div>
															</Link>
														</div>
														<div className="app-sub-solutions-right-wrap">
															<div className="app-sub-solutions-top">
																<Link to="/solutions/documentmanagersystem" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
																	<img className="app-icon-desktop" src="/app-icon-documentmanagement.png" alt="Document Management System" />
																	<img className="app-icon-mobile" src="/app-icon-documentmanagement-mobile.png" alt="Document Management System" />
																	<div className="app-data-en app-sub-solutions-label"><h5>Document Management System</h5></div>
																	<div className="app-data-vi app-sub-solutions-label"><h5>Quản Lý Tài Liệu (DMS)</h5></div>
																</Link>
															</div>
															<div className="app-sub-solutions-bottom">
																<Link to="/solutions/collaborationportal" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
																	<img className="app-icon-desktop" src="/app-icon-corporateportal.png" alt="Corporate Portal" />
																	<img className="app-icon-mobile" src="/app-icon-corporateportal-mobile.png" alt="Corporate Portal" />
																	<div className="app-data-en app-sub-solutions-label"><h5>Collaboration Portal</h5></div>
																	<div className="app-data-vi app-sub-solutions-label"><h5>Cổng Thông Tin Nội Bộ</h5></div>
																</Link>
															</div>
														</div>
													</div>
													{/* <div className="app-sub-solutions-wrap">
														<div className="app-sub-solutions-top-wrap">
															<Link to="/solutions/procurementtopayment" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
																<img src="/ProcurementtoPayment.png" alt="Procurement to Payment" />
																<div className="app-sub-solutions-label"><h5>Procurement to Payment</h5></div>
															</Link>
														</div>
														<div className="app-sub-solutions-bottom-wrap">
															<div className="app-sub-solutions-left">
																<Link to="/solutions/documentmanagersystem" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
																	<img src="/DocumentManagement.png" alt="Document Management System" />
																	<div className="app-sub-solutions-label"><h5>Document Management System</h5></div>
																</Link>
															</div>
															<div className="app-sub-solutions-right">
																<Link to="/solutions/contractmanagement" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
																	<img src="/ContractManagement.png" alt="Contract Management" />
																	<div className="app-sub-solutions-label"><h5>Contract Management</h5></div>
																</Link>
															</div>
														</div>
													</div> */}
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="app-subnav-row-morelink">
										<Col xs={12} md={12} lg={12}>
											<div className="app-morelink-wrap">
												<Link to="/solutions" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<h6 className="app-data-en"><span>See All Solutions<img className="app-icon-menunext" src="/app-icon-menunext.svg" /><img className="app-icon-menunext-mobile-tablet" src="/app-icon-menunext-mobile-tablet.svg" /></span></h6>
													<h6 className="app-data-vi"><span>Tất Cả Giải Pháp<img className="app-icon-menunext" src="/app-icon-menunext.svg" /><img className="app-icon-menunext-mobile-tablet" src="/app-icon-menunext-mobile-tablet.svg" /></span></h6>
												</Link>
											</div>
										</Col>
									</Row>
								</div>
							</li>
							<li className="has-sub">
								<h6 className='app-nav-services'>
									<Link className='app-nav-toplink' to="/services" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
										<span className="app-data-en app-display-none">Services</span>
										<span className="app-data-vi app-display-none">Dịch Vụ</span>
									</Link>
									<img className="app-icon-dot-notsub " src="/app-icon-dot.svg" alt="app icon dot" />
								</h6>
							</li>
							<li className="has-sub">
								<h6>
									<span className="app-data-en app-display-none">Support<img className="app-icon-menudown" src="/app-icon-menudown.svg" /></span>
									<span className="app-data-vi app-display-none">Hỗ Trợ<img className="app-icon-menudown" src="/app-icon-menudown.svg" /></span>
									<img className="app-icon-dot" src="/app-icon-dot.svg" alt="app icon dot" />
								</h6>
								<div className="sub">
									<Row id="app-subnav-row-header-supportcenter" className="app-subnav-row-header">
										<Col xs={12} md={12} lg={12}>
											<h4 id="app-sub-nav-headertext-supportcenter" className="app-sub-nav-headertext"><span className="app-data-en">Support Center</span><span className="app-data-vi">Hỗ Trợ</span></h4>
										</Col>
									</Row>
									<Row className="app-subnav-row-content">
										<Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<a href="/OnlineDoc">
													<span className="app-data-en">Online Docs</span>
													<span className="app-data-vi">Tài Liệu Trực Tuyến</span>
													<h6 className="app-data-en-info">Provide user guide, application notes, and technical related documents.</h6>
													<h6 className="app-data-vi-info">Hướng Dẫn Sử Dụng, Ghi chú, và những tài liệu Kỹ Thuật liên quan</h6>
												</a>
											</div>
										</Col>
										{/* <Col xs={12} md={12} lg={4}>
											<div className="app-sub-child">
												<Link to="/support/blogs" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<span className="app-data-en">Blogs</span>
													<span className="app-data-vi">Blogs</span>
													<h6 className="app-data-en-info">We share our experience related to applications and Microsoft technologies.</h6>
													<h6 className="app-data-vi-info">Chia sẽ kinh nghiệm liên qua đến ứng dụng của Appvity và công nghệ của Microsoft.</h6>
												</Link>
											</div>
										</Col> */}
									</Row>
									<Row className="app-subnav-row-morelink">
										<Col xs={12} md={12} lg={12}>
											<div className="app-morelink-wrap">
												<Link to="/support" onClick={handleScrollDisable} onKeyDown={handleScrollDisable}>
													<h6 className="app-data-en"><span>See Support Center<img className="app-icon-menunext" src="/app-icon-menunext.svg" /><img className="app-icon-menunext-mobile-tablet" src="/app-icon-menunext-mobile-tablet.svg" /></span></h6>
													<h6 className="app-data-vi"><span>Trung Tâm Hỗ Trợ<img className="app-icon-menunext" src="/app-icon-menunext.svg" /><img className="app-icon-menunext-mobile-tablet" src="/app-icon-menunext-mobile-tablet.svg" /></span></h6>
												</Link>
											</div>
										</Col>
									</Row>
								</div>
							</li>
						</ul>
						{/* <div className="app-login-signup">
							<Link to="/" className="app-login">Login</Link>
							<Link to="/" className="app-signup">
								<span className="app-signup-text">You don’t have an account yet.</span>
								<span>Signup</span>
							</Link>
						</div> */}
					</div>
					{/* <div className="app-online-store">
						<h6>
							<a href="https://300u26337848130.3dcartstores.com/" className="app-login">Go to Store 
								<img src="/app-icon-go-to-store.svg" alt="app icon go to store" />
							</a>
						</h6>
					</div> */}
					<CustomDropdown name="choice" />
				</div>
			</Container>
		</div>
	)
}
export default Header